<template>
  <div class="search-input-wrapper">
      <el-autocomplete style="width: 518px;" v-model="keyword" @keyup.enter.native="keywordSearch($event)" :fetch-suggestions="fetchSuggestions" :debounce=0 @input="completionSearch">
        <template slot="append">
          <div class="input-icon" style="cursor: pointer" @click="keywordSearch">
            <div class="icon" >
              <img src="@/assets/search2.png" alt="搜索">
            </div>
          </div>
        </template>
      </el-autocomplete>
<!--      <el-input placeholder="请输入内容" v-model="keyword" :fetch-suggestions="fetchSuggestions" :debounce=0 @keyup.native="completionSearch">-->
<!--            -->
<!--      </el-input>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { completionSearch } from '@/api/search'
export default {
  computed: {
    ...mapGetters(['searchKeyword'])
  },
  data () {
    return {
      keyword: '',
      completionList: []
    }
  },
  created () {
    if (this.$route.path === '/search') {
      this.keyword = this.searchKeyword
    } else {
      this.keyword = ''
    }
  },
  methods: {
    completionSearch () {
      if (this.keyword) {
        this.completionList = []
        // completionSearch({ text: this.keyword }).then((res) => {
        //   this.completionList = []
        //   for (let i = 0; i < res.data.data.length; i++) {
        //     this.completionList.push({ value: res.data.data[i] })
        //   }
        // })
      }
    },
    fetchSuggestions (string, callback) {
      callback(this.completionList)
    },
    setKeyWord (val) {
      this.keyword = val
      this.keywordSearch(val)
    },
    keywordSearch () {
      if (this.$route.path === '/search') {
        if (this.keyword) {
          this.$store.commit('SET_SEARCH_KEYWORD', this.keyword)
          this.$store.commit('SET_SEARCH_GOODS_ID', '')
          this.$emit('search', this.keyword)
        } else {
          this.$store.commit('SET_SEARCH_KEYWORD', '')
          // this.keyword = this.searchKeyword
          this.$emit('search', '9999')
        }
      } else {
        // if (!this.keyword) {
        //   this.keyword = '酷薯'
        // }
        // this.$store.commit('SET_SEARCH_KEYWORD', this.keyword)
        if (this.keyword) {
          this.$store.commit('SET_SEARCH_KEYWORD', this.keyword)
        } else {
          this.$store.commit('SET_SEARCH_KEYWORD', '')
        }
        this.$store.commit('SET_SEARCH_GOODS_ID', '')
        // this.$router.push({ path: 'search' })
        this.$router.push({
          // 调转到商品搜索页面
          path: '/search',
          query: {
            // commodityClass: 1005,
            // extName: '影院卖品',
            flag: '01'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input-wrapper {
  width: 518px;
  .input-icon {
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      height: 20px;
    }
  }
}
</style>
